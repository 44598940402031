import React, { useEffect, useState } from "react";
import { Button, Col, Form, Modal, Row, Spinner } from "react-bootstrap";
import { useFormik } from "formik";
import * as Yup from "yup";
import userDefault from "./../../assets/img/svgs/userDefault.svg";
import Loader1 from "../../assets/img/svgs/loader.svg";
import UserService from "../services/user.service";
import { useNavigate } from "react-router-dom";
import { enqueueSnackbar } from "notistack";

const TransferShareModal = ({
  shareModal,
  setShareModal,
  shareDetails,
  userData,
  portfolioData,
}) => {
  const email = localStorage.getItem("email");
  const [options, setOptions] = useState([]);
  const [toOptions, setToOptions] = useState([]);
  const [isTyping, setIsTyping] = useState(false);
  const [isToTyping, setIsToTyping] = useState(false);

  const handleEmailChange = (e) => {
    const value = e.target.value;
    if (value === "") {
      // Stay in typing mode if user is typing and value is empty
      setIsTyping(true);
    } else {
      // If there's a value, allow typing
      setIsTyping(true);
    }
    formik.handleChange(e);
  };

  // Handles the blur event to revert back to select if value is empty
  const handleBlur = (e) => {
    formik.handleBlur(e);
    if (formik.values.fromEmail === "") {
      setIsTyping(false); // Revert to select mode only if input is empty
    }
  };

  const handleToEmailChange = (e) => {
    const value = e.target.value;
    if (value === "") {
      // Stay in typing mode if user is typing and value is empty
      setIsToTyping(true);
    } else {
      // If there's a value, allow typing
      setIsToTyping(true);
    }
    formik.handleChange(e);
  };

  // Handle blur for 'To' email
  const handleToBlur = (e) => {
    formik.handleBlur(e);
    if (formik.values.toEmail === "") {
      setIsToTyping(false); // Revert to select if input is empty
    }
  };

  const initialValues = {
    fromEmail: "",
    propertyId: "",
    numberOfShare: "",
    toEmail: "",
    isFees: false,
  };

  // Validation schema using Yup
  const validationSchema = Yup.object({
    fromEmail: Yup.string()
      .email("Invalid email address")
      .required("From email is required"),
    // propertyId: Yup.string().required("Property ID is required"),
    numberOfShare: Yup.number()
      .required("Number of shares is required")
      .positive("Number must be positive"),
    toEmail: Yup.string()
      .email("Invalid email address")
      .required("To email is required"),
  });

  const handleSubmit = async (values, { setSubmitting }) => {
    const payload = { ...values, propertyId: shareDetails?.propertyId };
    try {
      if (values?.fromEmail === values?.toEmail) {
        throw new Error("You cannot transfer shares to yourself.");
      }
      const response = await UserService?.sellShare(payload);
      if (response) {
        enqueueSnackbar(
          response?.data?.data?.messsage || "Share transfer succesfully!",
          {
            variant: "success",
            anchorOrigin: {
              vertical: "bottom",
              horizontal: "right",
            },
          }
        );
        portfolioData();
        setSubmitting(false);
      }
    } catch (error) {
      setSubmitting(false);
      enqueueSnackbar(error?.response?.data?.message || error.message, {
        variant: "error",
        anchorOrigin: {
          vertical: "bottom",
          horizontal: "right",
        },
      });
    } finally {
      setShareModal(false);
    }
  };

  // Formik setup with validation and form submission
  const formik = useFormik({
    initialValues,
    validationSchema,
    onSubmit: handleSubmit,
  });

  const handleClose = () => {
    formik.resetForm();
    setShareModal(false);
  };

  useEffect(() => {
    setOptions([
      { value: email, label: email },
      { value: userData?.email, label: userData?.email },
    ]);
  }, [email, userData]);

  useEffect(() => {
    setToOptions([
      { value: email, label: email },
      { value: userData?.email, label: userData?.email },
    ]);
  }, [email, userData]);

  return (
    <>
      <Modal
        size="large"
        aria-labelledby="contained-modal-title-vcenter"
        centered
        backdrop="static"
        show={shareModal}
        onHide={handleClose}
      >
        <Modal.Header closeButton>
          <Modal.Title>Share transfer</Modal.Title>
        </Modal.Header>
        <Modal.Body
          className="text-center"
          style={{ paddingBottom: "initial" }}
        >
          <div className="col-122">
            <div className="card custom-card">
              <div
                className="col-12"
                style={{
                  overflow: "auto",
                  paddingTop: "15px",
                  marginBottom: "15px",
                  maxHeight: "650px",
                  padding: "0px 0px 0px 10px",
                }}
              >
                <div className="mb-3">
                  <img
                    src={userData?.profile || userDefault}
                    alt="profile"
                    width={100}
                    height={100}
                    style={{ borderRadius: "50%", objectFit: "cover" }}
                  />
                  <div
                    className="font-weight-bold"
                    style={{ fontSize: "large" }}
                  >
                    {userData?.username}
                  </div>
                </div>
                <Form onSubmit={formik.handleSubmit}>
                  <Row className="mb-2">
                    <Col xs={4} className="d-flex align-items-center">
                      <Form.Label
                        className="fw-bold text-start"
                        style={{ color: "black", marginBottom: "0px" }}
                      >
                        From
                      </Form.Label>
                    </Col>
                    <Col xs={8}>
                      {/* Conditional rendering based on whether the user is typing or selecting */}
                      {!isTyping ? (
                        <Form.Select
                          name="fromEmail"
                          value={formik.values.fromEmail || ""}
                          onChange={handleEmailChange}
                          onBlur={handleBlur}
                          isInvalid={
                            formik.touched.fromEmail && formik.errors.fromEmail
                          }
                        >
                          <option value="" disabled>
                            Select or type your email
                          </option>
                          {options.map((option, index) => (
                            <option key={index} value={option.value}>
                              {option.label}
                            </option>
                          ))}
                        </Form.Select>
                      ) : (
                        <Form.Control
                          type="text"
                          name="fromEmail"
                          value={formik.values.fromEmail}
                          onChange={handleEmailChange}
                          onBlur={handleBlur}
                          placeholder="Type your email"
                          autocomplete="off"
                          isInvalid={
                            formik.touched.fromEmail && formik.errors.fromEmail
                          }
                        />
                      )}

                      {/* Show validation errors */}
                      {formik.touched.fromEmail && formik.errors.fromEmail ? (
                        <div className="text-danger text-start">
                          {formik.errors.fromEmail}
                        </div>
                      ) : null}
                    </Col>
                  </Row>

                  <Row className="mb-2">
                    <Col xs={4} className="d-flex align-items-center">
                      <Form.Label
                        className="fw-bold text-start"
                        style={{
                          color: "black",
                          marginBottom: "0px",
                        }}
                      >
                        Property ID
                      </Form.Label>
                    </Col>
                    <Col xs={8}>
                      <Form.Control
                        type="text"
                        aria-label="Selected property"
                        name="propertyId"
                        value={`${shareDetails?.realestateNumber}(${shareDetails?.propertyName})`}
                        readOnly
                        isInvalid={
                          formik.touched.propertyId && formik.errors.propertyId
                        }
                      />

                      {formik.touched.propertyId && formik.errors.propertyId ? (
                        <div className="text-danger text-start">
                          {formik.errors.propertyId}
                        </div>
                      ) : null}
                    </Col>
                  </Row>

                  <Row className="mb-2">
                    <Col xs={4} className="d-flex align-items-center">
                      <Form.Label
                        className="fw-bold text-start"
                        style={{ color: "black", marginBottom: "0px" }}
                      >
                        Number of shares
                      </Form.Label>
                    </Col>
                    <Col xs={8}>
                      <Form.Control
                        type="number"
                        name="numberOfShare"
                        value={formik.values.numberOfShare}
                        onChange={formik.handleChange}
                        onBlur={formik.handleBlur}
                        isInvalid={
                          formik.touched.numberOfShare &&
                          formik.errors.numberOfShare
                        }
                      />
                      {formik.touched.numberOfShare &&
                      formik.errors.numberOfShare ? (
                        <div className="text-danger text-start">
                          {formik.errors.numberOfShare}
                        </div>
                      ) : null}
                    </Col>
                  </Row>

                  <Row className="mb-2">
                    <Col xs={4} className="d-flex align-items-center">
                      <Form.Label
                        className="fw-bold text-start"
                        style={{ color: "black", marginBottom: "0px" }}
                      >
                        To
                      </Form.Label>
                    </Col>
                    <Col xs={8}>
                      {!isToTyping ? (
                        <Form.Select
                          name="toEmail"
                          value={formik.values.toEmail || ""}
                          onChange={handleToEmailChange}
                          onBlur={handleToBlur}
                          isInvalid={
                            formik.touched.toEmail && formik.errors.toEmail
                          }
                        >
                          <option value="" disabled>
                            Select or type your email
                          </option>
                          {toOptions.map((option, index) => (
                            <option key={index} value={option.value}>
                              {option.label}
                            </option>
                          ))}
                        </Form.Select>
                      ) : (
                        <Form.Control
                          type="text"
                          name="toEmail"
                          value={formik.values.toEmail}
                          onChange={handleToEmailChange}
                          onBlur={handleToBlur}
                          autocomplete="off"
                          placeholder="Type your email"
                          isInvalid={
                            formik.touched.toEmail && formik.errors.toEmail
                          }
                        />
                      )}
                      {formik.touched.toEmail && formik.errors.toEmail ? (
                        <div className="text-danger text-start">
                          {formik.errors.toEmail}
                        </div>
                      ) : null}
                    </Col>
                  </Row>

                  <Row className="mb-2">
                    <Col xs={4} className="d-flex align-items-center">
                      <Form.Label
                        className="fw-bold text-start"
                        style={{ color: "black", marginBottom: "0px" }}
                      ></Form.Label>
                    </Col>
                    <Col xs={8} className="d-flex">
                      <Form.Check
                        type="checkbox"
                        label="With fees"
                        name="isFees"
                        checked={formik.values.isFees}
                        onChange={formik.handleChange}
                      />
                    </Col>
                  </Row>

                  <div
                    style={{
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                    }}
                  >
                    <Button
                      type="submit"
                      disabled={formik.isSubmitting}
                      style={{
                        display: "flex",
                        marginLeft: "auto",
                        marginRight: "15px",
                        textAlign: "center",
                      }}
                    >
                      {formik.isSubmitting ? <Spinner /> : "Submit"}
                    </Button>
                  </div>
                </Form>
              </div>
            </div>
          </div>
        </Modal.Body>
      </Modal>
    </>
  );
};

export default TransferShareModal;
