import React from "react";
import { Line } from "react-chartjs-2";
import { Chart as ChartJS, registerables } from "chart.js";
ChartJS.register(...registerables);

const MainDashboard = ({ valuation, tab }) => {
  const getMonthlyAmountData = (data) => {
    if (!data || data.length === 0) return Array(12).fill(0);
    const monthlyCounts = Array(12).fill(0);

    data.forEach((entry) => {
      const startDate = new Date(entry.startDate);
      const endDate = new Date(entry.endDate);
      const monthDiff =
        (endDate.getFullYear() - startDate.getFullYear()) * 12 +
        endDate.getMonth() -
        startDate.getMonth() +
        1;
      const valuePerMonth = entry.value / monthDiff;

      for (
        let date = new Date(startDate);
        date <= endDate;
        date.setMonth(date.getMonth() + 1)
      ) {
        const monthIndex = date.getMonth();
        monthlyCounts[monthIndex] += valuePerMonth;
      }
    });

    return monthlyCounts;
  };

  const getYearlyAmountData = (data) => {
    if (!data || data.length === 0) return [];

    const startYears = data.map((entry) =>
      new Date(entry.startDate).getFullYear()
    );
    const endYears = data.map((entry) => new Date(entry.endDate).getFullYear());
    const minYear = Math.min(...startYears);
    const maxYear = Math.max(...endYears);
    const yearRange = maxYear - minYear + 1;

    const yearlyCounts = Array(yearRange).fill(0);

    data.forEach((entry) => {
      const startDate = new Date(entry.startDate);
      const endDate = new Date(entry.endDate);
      const yearDiff = endDate.getFullYear() - startDate.getFullYear() + 1;
      const valuePerYear = entry.value / yearDiff;

      for (
        let year = startDate.getFullYear();
        year <= endDate.getFullYear();
        year++
      ) {
        const index = year - minYear;
        yearlyCounts[index] += valuePerYear;
      }
    });

    return yearlyCounts;
  };

  const generateLabels = (data, isOneYear) => {
    const labels = isOneYear
      ? [
          "Jan",
          "Feb",
          "Mar",
          "Apr",
          "May",
          "Jun",
          "Jul",
          "Aug",
          "Sep",
          "Oct",
          "Nov",
          "Dec",
        ]
      : data.map((_, index) => `${new Date().getFullYear() - 4 + index}`);
    return labels;
  };

  const oneYearData = getMonthlyAmountData(valuation);
  const fiveYearData = getYearlyAmountData(valuation);
  const oneYearLabels = generateLabels(valuation, true);
  const fiveYearLabels = generateLabels(fiveYearData, false);

  const chartData = {
    labels: tab === "oneYear" ? oneYearLabels : fiveYearLabels,
    datasets: [
      {
        label: "Valuation",
        data: tab === "oneYear" ? oneYearData : fiveYearData,
        backgroundColor: "#023220",
        borderColor: "#023220",
        borderWidth: 1,
        tension: 0.3,
        type: "bar",
      },
    ],
  };

  const chartOptions = {
    responsive: true,
    maintainAspectRatio: false,
    plugins: {
      title: { display: true },
      legend: { position: "top", display: true },
      tooltip: { enabled: true },
    },
    scales: {
      x: {
        ticks: { fontColor: "#c8ccdb" },
        barPercentage: 0.7,
        display: true,
        grid: { borderColor: "rgba(119, 119, 142, 0.2)" },
      },
      y: {
        beginAtZero: true,
        grid: { borderColor: "rgba(119, 119, 142, 0.2)" },
        ticks: {
          callback: (value) => `${value.toLocaleString()}`,
        },
      },
    },
    interaction: { intersect: false },
  };

  return (
    <Line
      options={chartOptions}
      data={chartData}
      className="barchart chart-dropshadow2 ht-300 chartjs-render-monitor"
      height="100"
    />
  );
};

export default MainDashboard;
