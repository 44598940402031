import { Typography } from "antd";
import React, { useState } from "react";
import { Card, Col, Pagination, Row, Badge } from "react-bootstrap";
import { Divider } from "@mui/material";
import UserService from "../../../services/user.service";
import { useEffect } from "react";
import { BiTransfer } from "react-icons/bi";
import { RiShareForwardFill } from "react-icons/ri";
import TransferShareModal from "../../PortfolioShare";
import RentalIncome from "../../RentalIncome";

const Portfolio = (props) => {
  const [page, setPage] = useState(1);
  const [totalData, setTotalData] = useState();
  const [cardData, setCardData] = useState([]);
  const [totalProfitPer, setTotalProfitPer] = useState("");
  const [total, setTotal] = useState("");
  const [invested, setInvested] = useState("");
  const [currentValuation, setCurrentValuation] = useState("");
  const [shareModal, setShareModal] = useState(false);
  const [incomeModal, setIncomeModal] = useState(false);
  const [shareDetails, setShareDetails] = useState("");
  const [shareIncomeDetails, setShareIncomeDetails] = useState("");
  const [userData, setUserData] = useState("");
  const limit = 6;

  const handleIncomeClose = () => {
    setIncomeModal(false);
  };

  const handleShare = (data, userData) => {
    setShareModal(true);
    setShareDetails(data);
    setUserData(userData);
  };

  const handleRentalIncome = (data, userData) => {
    setIncomeModal(true);
    setShareIncomeDetails(data);
    setUserData(userData);
  };

  const currentDate = new Date();
  const monthNames = [
    "January",
    "February",
    "March",
    "April",
    "May",
    "June",
    "July",
    "August",
    "September",
    "October",
    "November",
    "December",
  ];
  const month = monthNames[currentDate.getMonth()];
  const year = currentDate.getFullYear();

  const portfolioData = async () => {
    const response = await UserService.GetPortfolioDetails(
      props.id,
      page,
      limit
    );
    const result = response.data.data;
    setTotalData(result?.totalProperty);
    setCardData(result?.propertyData);
    setTotalProfitPer(result?.overallProfitOrLossPercentage);
    setTotal(result?.totalProfitOrLoss);
    setInvested(result?.totalInvestment);
    setCurrentValuation(result?.currentValuation);
  };
  useEffect(() => {
    portfolioData();
  }, [props.id, page]);

  const nextPage = (page) => {
    setPage(page);
  };
  const previousPage = (page) => {
    setPage(page);
  };

  return (
    <>
      <Row className="row-sm">
        <Col md={6} xs={12} lg={4}>
          <Card
            className="custom-card productdesc mt-3"
            style={{ background: "#7cb5a0" }}
          >
            <Card.Body className="h-100">
              <Row>
                <div className="d-flex justify-content-between">
                  <label
                    className="tx-res fw-bold text-light property-text"
                    title="Invested"
                  >
                    Invested
                  </label>
                  <label
                    className="tx-res fw-bold text-light property-text"
                    title="Current Valuation"
                  >
                    Current Valuation
                  </label>
                </div>
              </Row>
              <Row>
                <div className="d-flex justify-content-between">
                  <Typography
                    className="fw-bold tx-22 d-flex gap-2 align-items-baseline"
                    style={{ color: "#FFFFFF" }}
                  >
                    <div>
                      {invested} <span className="tx-16">KED</span>
                    </div>
                  </Typography>
                  <Typography
                    className="fw-bold tx-22 d-flex gap-2 align-items-baseline"
                    style={{ color: "#FFFFFF" }}
                  >
                    <div>
                      {currentValuation} <span className="tx-16">KED</span>
                    </div>
                  </Typography>
                </div>
              </Row>
              <Divider className="my-3" />
              <Row>
                <div className="d-flex justify-content-between align-items-baseline">
                  <label className="tx-18 fw-bold text-light">P&L</label>
                  <div className="d-flex align-items-baseline gap-2">
                    <Typography
                      className="fw-bold fs-6"
                      style={{
                        color: totalProfitPer > 0 ? "#1ED18E" : "#C30000",
                      }}
                    >{`${totalProfitPer}%`}</Typography>
                    <Typography
                      className="fw-bold tx-22 d-flex gap-2"
                      style={{ color: "#FFFFFF" }}
                    >
                      {" "}
                      <div>
                        {total} <span className="tx-16">KED</span>
                      </div>
                    </Typography>
                  </div>
                </div>
              </Row>
            </Card.Body>
          </Card>
        </Col>
      </Row>
      <Row>
        <h2
          className="main-content-title tx-24 mt-3"
          style={{ marginLeft: "4px" }}
        >
          Holdings({totalData})
        </h2>
        {cardData.map((card, index) => (
          <Col md={6} xs={12} lg={4}>
            {/*  <Card className="custom-card productdesc" key={index}>
              <Card.Body>
                <Row>
                  <Col>
                    <label
                      className="fw-bold tx-20 property-text"
                      title={card.title}
                    >
                      {card.title}
                    </label>
                  </Col>
                </Row>
                <Row>
                  <div className="d-flex justify-content-between">
                    <Typography
                      className="tx-18 user-text property-text"
                      title="Avg value:"
                    >
                      Avg value:
                    </Typography>
                    <Typography
                      className="tx-18 user-text property-text"
                      title="AvgCurrent value:"
                    >
                      Current value:
                    </Typography>
                  </div>
                </Row>
                <Row>
                  <div className="d-flex justify-content-between">
                    <Typography className="fw-bold fs-4 d-flex gap-2 align-items-baseline">
                      {" "}
                      <span className="tx-20 user-text">KD</span>
                      <span>{card.avgSharePrice}</span>
                    </Typography>
                    <Typography className="fw-bold fs-4 d-flex gap-2 align-items-baseline">
                      {" "}
                      <span className="tx-20 user-text">KD</span>
                      <span>{card.currentSharePrice}</span>
                    </Typography>
                  </div>
                </Row>
                <Row>
                  <div className="d-flex justify-content-between">
                    <Typography className="fw-bold tx-20 user-text d-flex gap-2 align-items-baseline">
                      {" "}
                      <span>Qty :</span>
                      <span>{card?.quantity}</span>
                    </Typography>
                    <Typography
                      className="fw-bold tx-20 user-text d-flex gap-2 align-items-baseline"
                      style={{
                        color:
                          card?.profitOrLossPercentage > 0
                            ? "#1ED18E"
                            : "#C30000",
                      }}
                    >
                      {" "}
                      {`${card?.profitOrLossPercentage}%`}
                    </Typography>
                  </div>
                </Row>
                <Row>
                  <div className="d-flex justify-content-between">
                    <Typography
                      className="tx-18 user-text property-text"
                      title="Avg Rental Incme"
                    >
                      Avg Rental Incme
                    </Typography>
                    <Typography
                      className="tx-18 user-text property-text"
                      title="This Month Rental:"
                    >
                      This Month Rental:
                    </Typography>
                  </div>
                </Row>
                <Row>
                  <div className="d-flex justify-content-between">
                    <Typography className="fw-bold fs-4 d-flex gap-2 align-items-baseline">
                      <span className="tx-20 user-text">KD</span>
                      <span>{card.totalRentalIncome}</span>
                    </Typography>
                    <Typography className="fw-bold fs-4 d-flex gap-2 align-items-baseline">
                      {" "}
                      <span>{card.curruntRentalIncome}</span>
                      <span className="tx-20 user-text">KED</span>
                    </Typography>
                  </div>
                </Row>
                <Row>
                  <div className="d-flex justify-content-between">
                    <Typography className="fw-bold tx-20 user-text d-flex gap-2 align-items-baseline">
                      {" "}
                      <span>12</span>
                      <span>months</span>
                    </Typography>
                    <Typography className="fw-bold tx-20 user-text d-flex gap-2 align-items-baseline">
                      {month}, {year}
                    </Typography>
                  </div>
                </Row>
              </Card.Body>
            </Card> */}
            <Card className="custom-card p-3 mb-0">
              <div className="align-items-center">
                <div
                  style={{
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    cursor: "pointer",
                  }}
                >
                  <img
                    src={card?.images[0] || ""}
                    style={{
                      width: "500px",
                      height: "250px",
                      // height: "200px",
                      // width: "500px",
                      objectFit: "cover",
                      borderRadius: "10px",
                    }}
                    alt="propertyimage"
                  />
                </div>
                <div style={{ width: "100%" }}>
                  <div className="d-flex flex-column">
                    <div className="">
                      <div className="d-flex align-items-center justify-content-between">
                        <label
                          className="tx-18 fw-bold mt-2 mb-0 property-text"
                          title="Property Name"
                        >
                          {card?.propertyName}
                        </label>
                      </div>
                      <Typography
                        className="text-muted property-text"
                        title={"hii"}
                      >
                        <span>RealEstate Number: </span>
                        <span
                          style={{ fontWeight: "bold" }}
                          className="text-muted"
                        >
                          {card?.realestateNumber}
                        </span>
                      </Typography>
                      <Row>
                        <div className="d-flex justify-content-between pt-2">
                          <Typography style={{ marginRight: "8px" }}>
                            <span
                              style={{ fontWeight: "bold" }}
                              className="text-muted"
                            >
                              Avg value:
                            </span>
                          </Typography>
                          <Typography style={{ marginRight: "8px" }}>
                            <span
                              style={{ fontWeight: "bold" }}
                              className="text-muted"
                            >
                              Current value:
                            </span>
                          </Typography>
                        </div>
                      </Row>
                      <Row>
                        <div className="d-flex justify-content-between">
                          <Typography className="fw-bold fs-4 d-flex gap-2 align-items-baseline">
                            <span className="tx-20 user-text">KD</span>
                            <span>{card.avgSharePrice}</span>
                          </Typography>
                          <Typography className="fw-bold fs-4 d-flex gap-2 align-items-baseline">
                            <span className="tx-20 user-text">KD</span>
                            <span>{card.currentSharePrice}</span>
                          </Typography>
                        </div>
                      </Row>
                      <Row>
                        <div className="d-flex justify-content-between">
                          <Typography className="fw-bold tx-20 user-text d-flex gap-2 align-items-baseline">
                            {" "}
                            <span>Qty :</span>
                            <span>{card?.quantity}</span>
                          </Typography>
                          <Typography
                            className="fw-bold tx-20 user-text d-flex gap-2 align-items-baseline"
                            style={{
                              color:
                                card?.profitOrLossPercentage > 0
                                  ? "#1ED18E"
                                  : "#C30000",
                            }}
                          >
                            {" "}
                            {`${card?.profitOrLossPercentage}%`}
                          </Typography>
                        </div>
                      </Row>
                      <Row>
                        <div className="d-flex justify-content-between pt-3">
                          <Typography style={{ marginRight: "8px" }}>
                            <span
                              style={{ fontWeight: "bold" }}
                              className="text-muted"
                            >
                              Avg Rental Incme:
                            </span>
                          </Typography>
                          <Typography style={{ marginRight: "8px" }}>
                            <span
                              style={{ fontWeight: "bold" }}
                              className="text-muted"
                            >
                              This Month Rental:
                            </span>
                          </Typography>
                        </div>
                      </Row>
                      <Row>
                        <div className="d-flex justify-content-between">
                          <Typography className="fw-bold fs-4 d-flex gap-2 align-items-baseline">
                            <span className="tx-20 user-text">KD</span>
                            <span>{card.totalRentalIncome}</span>
                          </Typography>
                          <Typography className="fw-bold fs-4 d-flex gap-2 align-items-baseline">
                            {" "}
                            <span>{card.curruntRentalIncome}</span>
                            <span className="tx-20 user-text">KED</span>
                          </Typography>
                        </div>
                      </Row>
                      <Row>
                        <div className="d-flex justify-content-between">
                          <Typography className="fw-bold tx-20 user-text d-flex gap-2 align-items-baseline">
                            {" "}
                            <span>12</span>
                            <span>months</span>
                          </Typography>
                          <Typography className="fw-bold tx-20 user-text d-flex gap-2 align-items-baseline">
                            {month}, {year}
                          </Typography>
                        </div>
                      </Row>
                    </div>
                    <div className="d-flex justify-content-end gap-2 mt-3">
                      <button
                        className="px-3 py-2 rounded-pill text-white"
                        style={{
                          cursor: "pointer",
                          backgroundColor: "rgb(34, 139, 34, 0.7)",
                          border: "none", // Remove default button border
                          outline: "none",
                        }}
                        onClick={() => handleShare(card, props?.userData)}
                      >
                        Transfer Share
                      </button>
                      <button
                        className="px-3 py-2 rounded-pill text-white"
                        style={{
                          cursor:
                            card?.totalRentalIncome == 0
                              ? "not-allowed"
                              : "pointer",
                          backgroundColor: "rgb(34, 139, 34, 0.7)",
                          border: "none", // Remove default button border
                          outline: "none",
                        }}
                        disabled={card?.totalRentalIncome == 0}
                        onClick={() => handleRentalIncome(card, props)}
                      >
                        Transfer Rental Income
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </Card>
          </Col>
        ))}

        {/*  {totalData > limit && (
          <Pagination
            className="pagination-circle mb-0 ms-lg-3"
            style={{ justifyContent: "center" }}
          >
            <Pagination.Item
              onClick={() => previousPage(page - 1)}
              disabled={page === 1}
            >
              <i className="fas fa-angle-double-left"></i>
            </Pagination.Item>
            {Array.from({ length: Math.ceil(totalData / limit) }).map(
              (_, index) => {
                const pageNumber = index + 1;
                return (
                  <Pagination.Item
                    onClick={() => nextPage(pageNumber)}
                    key={pageNumber}
                    active={pageNumber === page}
                  >
                    {pageNumber}
                  </Pagination.Item>
                );
              }
            )}
            <Pagination.Item
              onClick={() => nextPage(page + 1)}
              disabled={page === Math.ceil(totalData / limit)}
            >
              <i className="fas fa-angle-double-right"></i>
            </Pagination.Item>
          </Pagination>
        )} */}

        {shareModal && (
          <TransferShareModal
            shareModal={shareModal}
            setShareModal={setShareModal}
            shareDetails={shareDetails}
            userData={userData}
            portfolioData={portfolioData}
          />
        )}
        {incomeModal && (
          <RentalIncome
            incomeModal={incomeModal}
            handleIncomeClose={handleIncomeClose}
            shareIncomeDetails={shareIncomeDetails}
            userData={userData}
            setIncomeModal={setIncomeModal}
            portfolioData={portfolioData}
          />
        )}
      </Row>
    </>
  );
};

export default Portfolio;
